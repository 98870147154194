import httpClient from './auth-http-client'

async function getUser() {
    try {
        const response = await httpClient.get(`/user`)
        if (response.status === 200) {
            return response.data
        }
        return {}
    }
    catch (error) {
        console.error('Error:', error)
    }
}

async function useUpdateUser(changes: any) {
    try {
        const response = await httpClient.post(`/user/update`, changes)
        if (response.status === 200) {
            return response.data
        }
        return {}
    }
    catch (error) {
        console.error('Error:', error)
    }
}

async function setUserOnboarded() {
    try {
        const response = await httpClient.post(`/user/onboarded`)
        if (response.status === 200) {
            return response.data
        }
        return {}
    }
    catch (error) {
        console.error('Error:', error)
    }
}

async function setUserAvatar(avatar: string) {
    try {
        const response = await httpClient.post(`/user/avatar`, { url: avatar })
        if (response.status === 200) {
            return response.data
        }
        return {}
    }
    catch (error) {
        console.error('Error:', error)
    }
}

async function setUserProfile(updatedFields: any) {
    try {
        const response = await httpClient.patch(`/user/profile`, updatedFields)
        if (response.status === 200) {
            return response.data
        }
        return {}
    }
    catch (error) {
        console.error('Error:', error)
    }
}

async function getReminders(userId: string) {
    try {
        const response = await httpClient.get(`/user/${userId}/reminders`)
        if (response.status === 200) {
            return response.data
        }
        return {}
    }
    catch (error) {
        console.error('Error:', error)
    }
}

async function createReminder(reminder: any) {
    try {
        const response = await httpClient.post(`/user/reminders`, reminder)
        if (response.status === 200) {
            return response.data
        }
        return {}
    }
    catch (error) {
        console.error('Error:', error)
    }
}

async function updateReminder(reminder: any) {
    try {
        const response = await httpClient.patch(`/user/reminders`, reminder)
        if (response.status === 200) {
            return response.data
        }
        return {}
    }
    catch (error) {
        console.error('Error:', error)
    }
}

async function deleteReminder(reminderId: string) {
    try {
        const response = await httpClient.delete(`/user/reminders/${reminderId}`)
        if (response.status === 200) {
            return response.data
        }
        return {}
    }
    catch (error) {
        console.error('Error:', error)
    }
}
// delete user
async function deleteTeamMember(user_id: string) {
    try {
        const response = await httpClient.delete(`/user/${user_id}`)
        if (response.status === 200) {
            return response.data
        }
        return {}
    }
    catch (error) {
        console.error('Error:', error)
    }
}

async function updateTeamMember(member: any) {
    try {
        const response = await httpClient.patch(`/user`, member)
        if (response.status === 200) {
            return response.data
        }
        return {}
    }
    catch (error) {
        console.error('Error:', error)
    }
}

async function onboardCreator(body: any) {
    try {
        const response = await httpClient.post(`/user/onboard-creator`, body)
        if (response.status === 200) {
            return response.data
        }
        return {}
    }
    catch (error) {
        console.error('Error:', error)
    }
}
export { createReminder, deleteReminder, deleteTeamMember, getReminders, getUser, onboardCreator, setUserAvatar, setUserOnboarded, setUserProfile, updateReminder, updateTeamMember, useUpdateUser }
